import React, { useState, useCallback } from "react"
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { useForm } from "react-hook-form"
import {
  removeLampFromGroup,
  updateLampPermissionApi,
} from "helpers/backend_helper"
import { toast } from "react-toastify"
import _get from "lodash/get"
import useAppLamp from "hooks/useAppLamp"

const UpdateLampModal = ({ isOpen, toggle }) => {
  const [lamp, setLamp] = useState(null)
  const [loading, setLoading] = useState(false)
  const [overwrite, setOverwrite] = useState(false)
  const [showOverwrite, setShowOverwrite] = useState(false)
  const { register, handleSubmit, watch, errors, setValue } = useForm({
    defaultValues: {
      email: "",
      macId: "",
      groupId: "",
    },
  })
  const { getAppLampByMac } = useAppLamp()

  const mac = watch("macId")

  const onSubmit = useCallback(
    values => {
      setLoading(true)
      updateLampPermissionApi({
        ...values,
        overwrite,
        macId: values.macId.replaceAll(" ", ""),
        groupId: values.groupId.replaceAll(" ", ""),
      })
        .then(res => {
          const isErrorStatus = _get(res, "data.error.status", false)
          if (!isErrorStatus) {
            toast.success("Update Lamp Success!")
            loadAppLamp(values.macId)
            toggle()
          }
        })
        .catch(err => {
          const isErrorStatus = _get(err, "data.error.status", false)
          if (isErrorStatus) {
            if (
              err.data.error.message ===
              "Lamp is registered but not to the email field above"
            ) {
              setShowOverwrite(true)
            }
            toast.error(err.data.error.message)
          } else {
            toast.error(`${err.status} - ${err.statusText}`)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [overwrite]
  )

  const loadAppLamp = useCallback(
    async mac => {
      if (!mac) return
      setLoading(true)
      const _lamp = await getAppLampByMac(mac)
      setLoading(false)
      if (_lamp) {
        setLamp(_lamp)
      }
    },
    []
  )

  const loadAppLampHandler = useCallback(e => {
    e.preventDefault()

    if (!mac) {
      toast.error("Mac ID is required")
      return
    }

    loadAppLamp(mac)
  }, [mac, loadAppLamp])

  const removeFromGroup = useCallback(
    (lamp, groupId) => {
      setLoading(true)
      removeLampFromGroup({ lampId: lamp.id, groupId })
        .then(res => {
          const isErrorStatus = _get(res, "error.status", false)
          if (!isErrorStatus) {
            toast.success("Remove Lamp from Group Success!")
            loadAppLamp(lamp.mac)
          } else {
            toast.error(_get(res, "error.message", "Error"))
          }
        })
        .catch(err => {
          const isErrorStatus = _get(err, "data.error.status", false)
          if (isErrorStatus) {
            toast.error(err.data.error.message)
          } else {
            toast.error(`${err.status} - ${err.statusText}`)
          }
        })
        .finally(() => setLoading(false))
    },
    [loadAppLamp]
  )

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabindex="-1"
      toggle={toggle}
      backdrop="static"
      onClosed={() => {
        setOverwrite(false)
        setShowOverwrite(false)
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="modal-content">
        <ModalHeader toggle={toggle}>Update Lamp</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="example-text-input">User email</label>
                <div>
                  <input
                    className="form-control"
                    name="email"
                    type="text"
                    ref={register()}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="example-text-input">Mac ID</label>
                <div>
                  <input
                    className="form-control"
                    name="macId"
                    type="text"
                    ref={register()}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="form-group">
                <label htmlFor="example-text-input">Group ID (Topic)</label>
                <div>
                  <input
                    className="form-control"
                    name="groupId"
                    type="text"
                    ref={register()}
                    onBlur={e => {}}
                  />
                </div>
              </div>
            </div>
            {showOverwrite && (
              <div className="col-12 col-sm-6">
                <div className="form-group">
                  <div className="form-check">
                    <input
                      id="overwrite"
                      className="form-check-input"
                      name="overwrite"
                      type="checkbox"
                      checked={overwrite}
                      onChange={e => {
                        setOverwrite(e.target.checked)
                      }}
                    />
                    <label htmlFor="overwrite" className="form-check-label">
                      Overwrite lamp owner
                    </label>
                  </div>
                </div>
              </div>
            )}
            {!!lamp && (
              <div className="col-12 mt-5">
                <h5>LAMP INFORMATION (APP)</h5>
                {!!lamp.lamp && (
                  <>
                    <p>User: {lamp.user?.email}</p>
                    {Array.isArray(lamp.groups) &&
                      lamp.groups.map(group => (
                        <div className="row" key={group.id}>
                          <p className="col-8">
                            [{group.status}] Group {group.ordinalGroup}:{" "}
                            {group.topic}
                          </p>
                          <div className="col-4">
                            <a className="text-danger" onClick={() => removeFromGroup(lamp.lamp, group.groupId)}>
                              Remove
                            </a>
                          </div>
                        </div>
                      ))}
                  </>
                )}
                {!lamp.lamp && <p className="text-danger">Lamp not found</p>}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            color="success"
            onClick={loadAppLampHandler}
            disabled={loading}
          >
            Load By Mac
          </Button>
          <Button type="submit" color="primary" disabled={loading}>
            Save
          </Button>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default UpdateLampModal
