import { useCallback } from "react"
import _get from "lodash/get"
import { toast } from "react-toastify"
import { loadAppLampByMac } from "../helpers/backend_helper"

const useAppLamp = () => {
  const getAppLampByMac = useCallback(async mac => {
    if (!mac) return null
    const token = localStorage.getItem("token")

    const res = await loadAppLampByMac(token, mac).catch(err => {
      const isErrorStatus = _get(err, "data.error.status", false)
      if (isErrorStatus) {
        toast.error(err.data.error.message)
      } else {
        toast.error(`${err.status} - ${err.statusText}`)
      }
    })
    const isErrorStatus = _get(res, "error.status", false)
    if (!isErrorStatus) {
      return res.payload
    } else {
      toast.error(res.error.message)
    }
    return null
  }, [])

  return { getAppLampByMac }
}

export default useAppLamp
